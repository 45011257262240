export const POINT_FRAGMENT = `
    country {
        iso
        name
    }
    location {
        latitude
        longitude
    }
    name
    pointOfInterest
    shortName
`;

export const DRIVER_HOURS_STATEMENT_FRAGMENT = `
    id
    totalNetHours
    restdayShortage
    savingHoursBasis
    contractHours
    normalLoadedHours
    workingHoursDifference
    savedHoursReduction
    overtime
    underload
    overtimeBonus
    lastMonthBalance
    paidAmount
    totalAddition
    currentMonthBalance
    daysWorkedOld
    daysWorkedChange
    daysWorkedTotal
    sVDaysOld
    sVChange
    sVDaysTotal
    restdaysOld
    restdaysChange
    restdaysTotal
    statementDate
    sickdaysOld
    sickdaysChange
    sickdaysTotal
    workedHoursOld
    workedHoursTotal
    compensationRestdaysOld
    compensationRestdaysChange
    compensationRestdaysBalance
    compensationHolidaysOld
    compensationHolidaysChange
    compensationHolidaysBalance
    statutoryVacationBalance
    statutoryVacationBalanceOld
    statutoryVacationBalanceChange
    nonStatutoryVacationBalance
    nonStatutoryVacationBalanceOld
    nonStatutoryVacationBalanceChange
    employerVacationBalanceOld
    employerVacationBalanceChange
    employerVacationBalance
    workedHoursChange
    lines {
        isShift
        lines {
            id
            date
            activity
            start
            end
            description
            grossHours
            netHours
            oRT
            oBTHigh
            oBTLow
            expencesGross
            expencesNet
            needsUpdate
            parentLineId
            isVisible
        }
    }
    totalNetHours
    contractHours
    driver {
        firstName
        lastName
        fullName
    }
    status {
        description
        statusName
        id
    }
    hasSavedHoursBalance
`;
export const DRIVER_HOURS_STATEMENT_FRAGMENT_WITHOUT_LINES = `
    id
    totalNetHours
    restdayShortage
    savingHoursBasis
    contractHours
    normalLoadedHours
    workingHoursDifference
    savedHoursReduction
    overtime
    underload
    overtimeBonus
    lastMonthBalance
    paidAmount
    totalAddition
    currentMonthBalance
    daysWorkedOld
    daysWorkedChange
    daysWorkedTotal
    sVDaysOld
    sVChange
    sVDaysTotal
    restdaysOld
    restdaysChange
    restdaysTotal
    statementDate
    sickdaysOld
    sickdaysChange
    sickdaysTotal
    workedHoursOld
    workedHoursTotal
    workedHoursChange
    compensationRestdaysOld
    compensationRestdaysChange
    compensationRestdaysBalance
    compensationHolidaysOld
    compensationHolidaysChange
    compensationHolidaysBalance
    statutoryVacationBalance
    statutoryVacationBalanceOld
    statutoryVacationBalanceChange
    nonStatutoryVacationBalance
    nonStatutoryVacationBalanceOld
    nonStatutoryVacationBalanceChange
    employerVacationBalanceOld
    employerVacationBalanceChange
    employerVacationBalance
    totalNetHours
    contractHours
    hasSavedHoursBalance
    driver {
        firstName
        lastName
        fullName
    }
    status {
        description
        statusName
        id
    }
`;
