/* src/app/driver-hours/worked-hours/modals/send-emails-modal/send-emails-modal.component.scss */
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
::ng-deep .modal-class .modal-dialog {
  width: 781px;
  max-width: 781px;
}
.email__container {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
}
.email__subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0b162d;
  margin: 0 -15px;
}
.email__body {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.row {
  display: grid;
  grid-template-columns: 114px auto;
}
.row:first-child {
  margin-top: 0 !important;
}
.row:last-child {
  margin-top: 20px !important;
}
.row__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0b162d;
}
textarea {
  height: 77px;
  padding: 10px 14px;
  border-radius: 4px;
  border: 1px solid #dbdfe8;
  resize: none;
  color: #828282;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-30 {
  margin-top: 40px;
}
.from-input {
  border: none;
}
/*# sourceMappingURL=send-emails-modal.component.css.map */
