import { Injectable } from '@angular/core';
import { I18nService } from '../../../shared/i18n/i18n.service';
import { BusAttributes } from '../../../data/busses/models/bus.attributes';

@Injectable({
    providedIn: 'root'
})
export class StatementDateParserService {
    private _statementDate: Date;

    constructor(
        private i18Service: I18nService,
    ) { }

    setStatementDate(value: string | Date) {
        this._statementDate = new Date(value);
    }

    getStatementDate(): Date {
        return this._statementDate;
    }

    get year(): number {
        return this._statementDate.getFullYear();
    }

    get month(): string {
        const months = [
            'january', 'february', 'march', 'april', 'may', 'june',
            'july', 'august', 'september', 'october', 'november', 'december'
        ];
        return this.i18Service.getTranslatedWord(`workedHours.filterLabels.${months[this._statementDate.getMonth()]}`);
    }
}
