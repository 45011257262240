import { Injectable } from "@angular/core";
import gql from "graphql-tag";
import { Observable, Subject } from "rxjs";
import { pluck, map } from "rxjs/operators";
import { GraphqlService } from "src/app/services/graphql.service";
import {
    DriverHoursResult,
    DriverHoursStatementResponse,
    IDriverHoursFilter,
    IDriverHoursLineRequest,
    IDriverHoursStatement,
    StatementsPaginationType,
} from "../shared/driver-hours.model";
import {
    ICombinedAttachment,
    IEmailDriverHoursStatementData,
    LanguagePreferences,
    ListSortDirection
} from "../shared/interfaces";
import {
    DRIVER_HOURS_STATEMENT_FRAGMENT,
    DRIVER_HOURS_STATEMENT_FRAGMENT_WITHOUT_LINES,
} from "./drivet-hour-statement.fragment";

@Injectable({
    providedIn: "root",
})
export class DriverHoursService {
    constructor(private graphqlService: GraphqlService) {}

    public closeWithSave$: Subject<boolean> = new Subject<boolean>();

    public driverHoursStatementById<T extends DriverHoursStatementResponse>(
        id: number,
        paginatedBy: StatementsPaginationType,
    ): Observable<T> {
        const query = gql`
    query driverHoursStatementById($id: Int , $paginatedBy: StatementsPaginationType) {
        driverHoursStatementById(id: $id, paginatedBy: $paginatedBy) {
            nextStatementId
            previousStatementId
            statement {
                ${DRIVER_HOURS_STATEMENT_FRAGMENT}
            }
        }
    }
    `;

        const variables = {
            id,
            paginatedBy,
        };

        return this.graphqlService
            .query<{ driverHoursStatementById: T }>(query, variables)
            .pipe(pluck("data", "driverHoursStatementById"));
    }

    public driverHours(
        filter: IDriverHoursFilter,
        take: number,
        skip: number,
        sortBy?: string,
        sortingOrder?: ListSortDirection,
    ): Observable<DriverHoursResult> {
        const query = gql`
        query driverHours($filter: DriverHoursFilter , $take: Int, $skip: Int, $sortBy: String, $sortingOrder: ListSortDirection) {
            driverHours(filter: $filter, take: $take, skip: $skip, sortBy: $sortBy, sortingOrder: $sortingOrder) {
                allStatementsIds
                statements {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT_WITHOUT_LINES}
                }
            }
        }
    `;

        const variables = {
            filter: {
                ...filter,
                month: +filter.month,
                year: +filter.year,
                driverId: filter.driverId ? +filter.driverId : null,
            },
            take,
            skip,
            sortBy,
            sortingOrder,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "driverHours"));
    }

    public driverHoursLines(
        filter: IDriverHoursFilter,
        take: number,
        skip: number,
        id?: number,
        sortBy?: string,
        sortingOrder?: ListSortDirection,
    ): Observable<DriverHoursResult> {
        const query = gql`
        query driverHours($filter: DriverHoursFilter , $take: Int, $skip: Int, $id: Int, $sortBy: String, $sortingOrder: ListSortDirection) {
            driverHours(filter: $filter, take: $take, skip: $skip, id: $id, sortBy: $sortBy, sortingOrder: $sortingOrder) {
                allStatementsIds
                statements {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        }
    `;

        const variables = {
            filter: {
                ...filter,
                month: +filter.month,
                year: +filter.year,
                driverId: filter.driverId ? +filter.driverId : null,
            },
            take,
            skip,
            id,
            sortBy,
            sortingOrder,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "driverHours"));
    }

    public createDriverHoursLine(
        id: number,
        createdLine: Partial<IDriverHoursLineRequest>
    ): Observable<IDriverHoursStatement> {
        const mutation = gql`
            mutation createDriverHoursLine($id: Int!, $createdLine: DriverHoursLineInput) {
                createDriverHoursLine(id: $id, createdLine: $createdLine) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;

        const variables = {
            id,
            createdLine
        };

        return this.graphqlService
            .mutate(mutation, variables)
            .pipe(pluck("data", "createDriverHoursLine"));
    }

    public deleteDriverHoursLine(
        id: number,
        lineId: number
    ): Observable<IDriverHoursStatement> {
        const mutation = gql`
            mutation deleteDriverHoursLine($id: Int!, $lineId: Int!) {
                deleteDriverHoursLine(id: $id, lineId: $lineId) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;

        const variables = {
            id,
            lineId
        };

        return this.graphqlService
            .mutate(mutation, variables)
            .pipe(pluck("data", "deleteDriverHoursLine"));
    }

    public editDriverHoursLine(
        id: number,
        editedLine: IDriverHoursLineRequest
    ): Observable<IDriverHoursStatement> {
        const mutation = gql`
            mutation editDriverHoursLine($id: Int!, $editedLine: DriverHoursLineInput) {
                editDriverHoursLine(id: $id, editedLine: $editedLine) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;

        const variables = {
            id,
            editedLine
        };

        return this.graphqlService
            .mutate(mutation, variables)
            .pipe(pluck("data", "editDriverHoursLine"));
    }

    public createDriverHoursStatement(
        driverId: number,
        year: number,
        month: number,
    ): Observable<IDriverHoursStatement> {
        const mutation = gql`
            mutation createDriverHoursStatement(
                $driverId: Int!
                $year: Int!
                $month: Int!
            ) {
                createDriverHoursStatement(
                    driverId: $driverId
                    year: $year
                    month: $month
                ) {
                    lines {
                        activity
                        date
                    }
                }
            }
        `;

        const variables = {
            driverId: +driverId,
            year: +year,
            month: +month,
        };

        return this.graphqlService
            .mutate(mutation, variables)
            .pipe(pluck("data", "createDriverHoursStatement"));
    }

    public driverHoursFromSwitcher(
        year: number = null,
        month: number = null,
        driverId: number = null,
    ): Observable<IDriverHoursStatement> {
        const query = gql`
            query driverHoursFromSwitcher($year: Int, $month: Int, $driverId: Int) {
                driverHoursFromSwitcher(year: $year, month: $month, driverId: $driverId) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;
        const variables = {
            year,
            month,
            driverId,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "driverHoursFromSwitcher"));
    }

    public setStatusDriverHours<T extends { statementIds: number[] }>(
        statementIds: number[],
        status: string,
        letter: IEmailDriverHoursStatementData = null
    ): Observable<number[]> {
        const mutation = gql`
        mutation setStatusDriverHours(
            $statementIds: [Int]
            $status: String
            $letter: DriverHoursStatementLetterInput
        ) {
            setStatusDriverHours(
                statementIds: $statementIds
                status: $status
                letter: $letter
            ) {
                statementIds
            }
        }
    `;
        const variables = {
            statementIds,
            status,
            letter
        };

        return this.graphqlService.query<{ setStatusDriverHours: T }>(mutation, variables).pipe(
            pluck("data", "setStatusDriverHours"),
            map((res) => res.statementIds),
        );
    }

    public exportDriverHours(statementIds: number[]): Observable<string> {
        const mutation = gql`
            mutation exportDriverHours($statementIds: [Int]) {
                exportDriverHours(statementIds: $statementIds)
            }
        `;
        const variables = {
            statementIds,
            status,
        };

        return this.graphqlService
            .query(mutation, variables)
            .pipe(pluck("data", "exportDriverHours"));
    }

    public generateCombinedDriverHoursStatementFile(
        ids: number[],
        language: LanguagePreferences
    ): Observable<ICombinedAttachment> {
        const mutation = gql`
            mutation generateCombinedDriverHoursStatementFile($ids: [Int], $language: LanguagePreferences) {
                generateCombinedDriverHoursStatementFile(ids: $ids, language: $language) {
                    id
                    url
                    filename
                }
            }
        `;
        const variables = {
            ids,
            language
        };

        return this.graphqlService
            .query(mutation, variables)
            .pipe(pluck("data", "generateCombinedDriverHoursStatementFile"));
    }

    public acceptDriverHoursLineChanges(
        statementId: number,
        lineId: number,
    ): Observable<IDriverHoursStatement> {
        const query = gql`
            mutation acceptDriverHoursLineChanges($statementId: Int, $lineId: Int) {
                acceptDriverHoursLineChanges(statementId: $statementId, lineId: $lineId) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;
        const variables = {
            statementId,
            lineId,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "acceptDriverHoursLineChanges"));
    }

    public declineDriverHoursLineChanges(
        statementId: number,
        lineId: number,
    ): Observable<IDriverHoursStatement> {
        const query = gql`
            mutation declineDriverHoursLineChanges($statementId: Int, $lineId: Int) {
                declineDriverHoursLineChanges(statementId: $statementId, lineId: $lineId) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;
        const variables = {
            statementId,
            lineId,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "declineDriverHoursLineChanges"));
    }

    public updateSavedHours(
        id: number,
        paidHours: number,
    ): Observable<IDriverHoursStatement> {
        const query = gql`
            mutation updateSavedHours($id: Int!, $paidHours: Float!) {
                updateSavedHours(id: $id, paidHours: $paidHours) {
                    ${DRIVER_HOURS_STATEMENT_FRAGMENT}
                }
            }
        `;
        const variables = {
            id,
            paidHours,
        };

        return this.graphqlService
            .query(query, variables)
            .pipe(pluck("data", "updateSavedHours"));
    }
}
