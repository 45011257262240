<ge-modal-window>
    <form [formGroup]="emailForm" class="email__container">
        <h5 *ngIf="!isSingle" class="email__subtitle">
            {{ "workedHours.modal.subtitle" | i18n }}
        </h5>
        <div class="email__body">
            <div class="row">
                <div class="row__label">
                    {{ "workedHours.modal.from" | i18n }}
                </div>
                <div>
                    <input
                        class="from-input"
                        formControlName="from"
                        type="text"
                    />
                </div>
            </div>
            <ng-container *ngIf="isSingle">
                <div class="row margin-top-15">
                    <div class="row__label">To:</div>
                    <ge-email-list-management-control
                        formControlName="to"
                        [emailList]="to"
                        (changed)="onEmailListChanged('to')"
                    ></ge-email-list-management-control>
                </div>
                <div class="row margin-top-15">
                    <div class="row__label">Cc:</div>
                    <ge-email-list-management-control
                        formControlName="cc"
                        [emailList]="cc"
                        (changed)="onEmailListChanged('cc')"
                    ></ge-email-list-management-control>
                </div>
            </ng-container>
            <div
                class="row"
                [ngClass]="isSingle ? 'margin-top-15' : 'margin-top-30'"
            >
                <div class="row__label">
                    {{ "workedHours.modal.subject" | i18n }}
                </div>
                <ge-input formControlName="subject"></ge-input>
            </div>
            <div class="row margin-top-15">
                <div class="row__label">
                    {{ "workedHours.modal.letter" | i18n }}
                </div>
                <ge-text-editor formControlName="letter"></ge-text-editor>
            </div>
            <div class="row margin-top-30">
                <div class="row__label">
                    {{ "workedHours.modal.attachments" | i18n }}
                </div>
                <ge-attachment-preview
                    [isEdit]="true"
                    [attachment]="attachments"
                >
                </ge-attachment-preview>
            </div>
            <div class="row margin-top-30">
                <div class="row__label"></div>
                <ge-upload
                    #attachmentUploader
                    dropAllowed="true"
                    [multiple]="true"
                ></ge-upload>
            </div>
        </div>
        <div class="email__buttons"></div>
    </form>
</ge-modal-window>
