import { Component, OnInit } from '@angular/core';
import { ModalWindowService } from '../../../../shared/components/modal-window/modal-window.service';
import { IModalWindowOptions } from '../../../../shared/components/modal-window/modal-options.interface';
import { IDriverHoursStatement } from '../../../shared/driver-hours.model';
import { StatementDateParserService } from '../../shared/statement-date-parser.service';

@Component({
    selector: "ge-export-exact-specific-error-modal",
    templateUrl: "./export-exact-specific-error-modal.component.html",
    styleUrls: ["./export-exact-specific-error-modal.component.scss"],
})
export class ExportExactSpecificErrorModalComponent implements OnInit {

    public data: { statement: IDriverHoursStatement };

    modalWindowOptions: IModalWindowOptions = {
        title: "driverDetails.setDriverHoursActions.errorOccurredModalTitle",
        buttons: [
            {
                label: "driverDetails.setDriverHoursActions.okButton",
                click: this.close.bind(this)
            }
        ],
        isNotCancellable: true
    };

    constructor(
        private modalWindowService: ModalWindowService,
        public statementDateParserService: StatementDateParserService
    ) { }

    driverFullName(): string | null {
        return this.data?.statement?.driver?.fullName ?? null;
    }

    ngOnInit() {
        this.statementDateParserService.setStatementDate(this.data.statement.statementDate);
    }

    close(): void {
        this.modalWindowService.close();
    }
}
