import { IModalWindowOptions } from "./../../../../shared/components/modal-window/modal-options.interface";
import { UploadComponent } from "./../../../../shared/components/upload/upload/upload.component";
import { UntypedFormBuilder } from "@angular/forms";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { EmailDetailService } from "../../../../orders/email-details-popup/email-detail-service.service";
import { take } from "rxjs/operators";
import { ModalWindowService } from "../../../../shared/components/modal-window/modal-window.service";
import { ToasterNotificationService } from "../../../../shared/components/toaster-notification/toaster-notification.service";
import { I18nService } from "../../../../shared/i18n/i18n.service";
import { DriverHoursService } from "../../../services/driver-hours.service";
import { LanguagePreferences } from "../../../shared/interfaces";

@Component({
    selector: "ge-send-multiple-emails-modal",
    templateUrl: "./send-emails-modal.component.html",
    styleUrl: "./send-emails-modal.component.scss",
})
export class SendEmailsModalComponent implements OnInit, AfterViewInit {
    @ViewChild(UploadComponent)
    attachmentUploader: UploadComponent;

    private data: { driverHoursStatementIds: number[] };
    private isSubmitDisabled = false;

    public to: string[] = [];
    public cc: string[] = [];

    public emailForm = this._fb.group({
        from: null,
        to: null,
        cc: null,
        subject: null,
        letter: null,
    });

    get isSingle(): boolean {
        return this.data.driverHoursStatementIds.length === 1;
    }

    public attachments: any[] = [];

    modalWindowOptions: IModalWindowOptions = {
        ngbOptions: {
            windowClass: "extra-lg",
        },
        title: "emailDetailsPopup.title",
        buttons: [
            {
                label: "emailDetailsPopup.saveBtn",
                click: this.save.bind(this),
                disabled: () => this.isSubmitDisabled,
            },
        ],
    };

    constructor(
        private _fb: UntypedFormBuilder,
        private _emailService: EmailDetailService,
        private modalWindowService: ModalWindowService,
        private toasterNotificationService: ToasterNotificationService,
        private i18Service: I18nService,
        private driverHoursService: DriverHoursService,
    ) {}

    private save(): void {
        this.isSubmitDisabled = true;

        const letter = this.emailForm.getRawValue();
        const ids = this.data.driverHoursStatementIds;
        const lang = LanguagePreferences[this.i18Service.getCurrentLanguage()];

        this.attachmentUploader.attach();

        this.driverHoursService
            .setStatusDriverHours(ids, "Sent", { ...letter, language: lang })
            .pipe(take(1))
            .subscribe((response) => {
                if (response) {
                    this.modalWindowService.close(response);
                } else {
                    this.toasterNotificationService.errorToaster(
                        this.i18Service.getTranslatedWord(
                            "invoiceEmailPopup.sendEmailError",
                        ),
                    );
                }
                this.isSubmitDisabled = false;
            });
    }

    public onEmailListChanged(controlName: string) {
        this.emailForm.get(controlName).updateValueAndValidity();
    }

    ngAfterViewInit() {
        this.attachmentUploader.onChanged.subscribe((file) => {
            this.attachments.push(file);
        });
    }

    ngOnInit(): void {
        this.loadData();
    }

    private loadData(): void {
        const ids = this.data.driverHoursStatementIds;

        this._emailService
            .getEmailDriverHoursStatementData(ids)
            .pipe(take(1))
            .subscribe(
                (response) => {
                    this.emailForm.patchValue(response);

                    this.to = response.to;
                    this.cc = response.cc;
                },
                (error) => {
                    console.log(error);
                },
            );
    }
}
